import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/web.dom.iterable";

/*
 * @Author: yangchao
 * @Date: 2021-07-16 17:17:19
 * @LastEditors: yangchao
 * @LastEditTime: 2021-11-26 11:13:45
 * @Description: 基础数据
 */
import { getEmployees } from '@/utils/common';
import store from '@/store';
import { labelOptions } from '@/views/project/constants';
console.log('store', store);
var customerImportant = store && store.getters.customerLevel || [];
var customerLevelManual = store && store.getters.customerLevelManual || [];
var intensList = store && store.getters.intensList || [];
var userInfo = store && store.getters.userInfo || [];
var levelList2 = []; // const jzTypeList = [{ title: '已接诊', field: 'Y' }, { title: '未接诊', field: 'N' }];
// const JdStatusFilter = (status, type) => {
//   const map = {
//     Y: { type: 'success', title: '已接诊' },
//     N: { type: 'warning', title: '未接诊' },
//   };
//   return map[status] ? map[status][type] : status;
// };

var getCustomerLevelList = function getCustomerLevelList() {
  customerLevel.forEach(function (ele) {
    var item = customerLevelManual.findIndex(function (val) {
      return val.level === ele.field;
    });

    if (item !== -1) {
      levelList2.push(ele);
    } else {
      ele.disabled = true;
      levelList2.push(ele);
    }
  });
};

export var jzStateFilter = function jzStateFilter(status, type) {
  var map = {
    FST: {
      type: 'success',
      title: '初诊'
    },
    FID: {
      type: 'danger',
      title: '复诊'
    },
    THR: {
      type: 'primary',
      title: '再成交'
    },
    AUDIT: {
      type: 'warning',
      title: '待审核'
    },
    CHK: {
      type: 'info',
      title: '复查'
    },
    OTH: {
      type: 'info',
      title: '其他'
    }
  };
  return map[status] ? map[status][type] : status;
};
export var getColumnValueRed = function getColumnValueRed(value, list, row) {
  if (!value) {
    value = '';
  }

  return '<span style="color:red"> ' + value + '</span>';
};
export var getColumnValueEditTable = function getColumnValueEditTable(value, list, row) {
  var doc;

  if (list) {
    list.map(function (item) {
      if (item.field === value) {
        doc = item;
      }
    });
  }

  return doc ? doc.title : value;
};
export var customerLevel = store && store.getters.customerLevel || [];
export var importList2 = customerImportant.map(function (v) {
  return {
    title: v.name,
    field: v.code
  };
});
export var tyOrZhList = [{
  title: '体验',
  field: '1'
}, {
  title: '转化',
  field: '2'
}];
export var statusList = [{
  title: '初诊',
  field: 'FST',
  type: 'success'
}, {
  title: '复诊',
  field: 'FID',
  type: 'info'
}, {
  title: '再成交',
  field: 'THR',
  type: 'warning'
}, {
  title: '复查',
  field: 'CHK',
  type: 'danger'
}, // { title: '待审核', field: 'AUDIT', type: 'primary' },
{
  title: '其他',
  field: 'OTH',
  type: 'primary'
}];
export var stateList = [{
  title: '成交',
  field: 'SUC',
  type: 'primary'
}, {
  title: '未成交',
  field: 'FAL',
  type: 'danger'
}, {
  title: '待审核',
  field: 'AUDIT'
}];
export var yesOrNoList = [{
  title: '是',
  field: 'Y'
}, {
  title: '否',
  field: 'N'
}];
export var checkList = [{
  title: '已开通',
  field: '1'
}, {
  title: '未开通',
  field: '0'
}];
export var stateFilter = function stateFilter(status, type) {
  var map = {
    CAN: {
      type: 'warning',
      title: '取消'
    },
    FAL: {
      type: 'danger',
      title: '未成交'
    },
    NEW: {
      type: 'primary',
      title: '新单'
    },
    SUC: {
      type: 'success',
      title: '成交'
    },
    AUDIT: {
      type: 'warning',
      title: '待审核'
    },
    NFM: {
      type: 'info',
      title: '复查'
    }
  };
  return map[status] ? map[status][type] : status;
};
export var checkStateFilter = function checkStateFilter(status, type) {
  var map = {
    '0': {
      type: 'success',
      title: '待审核'
    },
    '1': {
      type: 'danger',
      title: '审核通过'
    }
  };
  return map[status] ? map[status][type] : status;
};
export var tyOrZhFilter = function tyOrZhFilter(status, type) {
  var map = {
    '1': {
      type: 'success',
      title: '体验'
    },
    '2': {
      type: 'primary',
      title: '转化'
    }
  };
  return map[status] ? map[status][type] : '';
};
export var tyCanEdit = function tyCanEdit() {
  if (userInfo.GLOBAL_RIGHT) {
    return true;
  } else {
    return false;
  }
};
export var getEmployeeTypeList = function getEmployeeTypeList(type, status) {
  return []; // // console.log('在职的员工', getEmployees());
  // if (getEmployees()) {
  //   if (!type) {
  //     // 返回在职的所有员工
  //     return getEmployees();
  //   }
  //   // 过滤类型所有员工
  //   let arr = getEmployees().filter((v) => {
  //     if (v.postTypes && v.postTypes.length > 0) {
  //       let findIndex = v.postTypes.findIndex((cv) => {
  //         return cv === type;
  //       });
  //       if (findIndex > -1) {
  //         return v;
  //       }
  //     }
  //   });
  //   // status不传，返回所有员工
  //   if (!status) {
  //     return arr;
  //   }
  //   // 只返回在职员工
  //   return arr.filter((v) => {
  //     return v.empStatus === 'CFM';
  //   });
  // }
  // return [];
};
export var waitFilter = function waitFilter(status, type) {
  var map = {
    1: {
      type: 'info',
      title: '待分配'
    },
    2: {
      type: 'success',
      title: '已分配'
    }
  };
  return map[status] ? map[status][type] : status;
};
getCustomerLevelList();
export var consultColumns = function consultColumns() {
  return [{
    title: '',
    fixed: 'right',
    field: 'waitDistribute',
    filter: waitFilter,
    width: '60px',
    searchList: [{
      title: '待分配',
      field: 1
    }, {
      title: '已分配',
      field: 2
    }],
    formatter: getColumnValueEditTable
  }, {
    title: '来院时间',
    field: 'ctfInTime',
    operate: 'datetime',
    width: '95px',
    fixed: 'left'
  }, // {
  //   title:'状态',
  //   fixed: 'left',
  //   field:'ctfJdStatus',
  //   width:'80px',
  //   searchList: jzTypeList,
  //   filter: JdStatusFilter,
  //   formatter: getColumnValueEditTable
  // },
  {
    title: '客户卡号',
    fixed: 'left',
    field: 'ctfCtmcode',
    width: '100px'
  }, {
    title: '客户姓名',
    fixed: 'left',
    field: 'ctfCtmname',
    width: '60px'
  }, {
    title: '所属顾问',
    field: 'ctmEmpcodeName',
    width: '80px',
    fixed: 'left'
  }, {
    title: '会员手机号',
    field: 'ctfTel',
    width: '100px'
  }, {
    title: '接诊状态',
    field: 'ctfStatus',
    width: '70px',
    searchList: statusList,
    formatter: getColumnValueEditTable,
    filter: jzStateFilter
  }, {
    title: '来院目的',
    field: 'ctfLyMd',
    width: '80px'
  }, {
    title: '咨询沟通情况',
    field: 'ctfRemark',
    field1: 'ctfRemark2',
    // catStr: true,
    width: '180px'
  }, {
    title: '护理师/美容师沟通情况',
    field: 'ctfMrsRemark',
    catStr: true,
    width: '180px'
  }, {
    title: '剩余项目',
    field: 'ctfSyProject',
    width: '180px'
  }, // { title: '铺垫项目', field: 'ctfPdProject', width: '120px' },
  {
    title: '铺垫项目',
    field: 'ctfQtype1',
    searchList: this.intensList,
    width: '100px',
    formatter: getColumnValueEditTable
  }, // {
  //   title: '执行医生',
  //   field: 'ctfZxDoctorName',
  //   width: '80px',
  //   searchList: getEmployeeTypeList('zzys'),
  //   formatter: getColumnValueEditTable,
  // },
  // {
  //   title: '执行护理师',
  //   field: 'ctfZxHlsName',
  //   width: '80px',
  //   searchList: getEmployeeTypeList('sshls'),
  //   formatter: getColumnValueEditTable,
  // },
  {
    title: '接诊顾问',
    field: 'ctfJdEmpName',
    width: '80px',
    searchList: getEmployeeTypeList('jzgw')
  }, {
    title: '联系电话',
    field: 'ctmTel',
    width: '120px'
  }, {
    title: '档案号',
    field: 'ctmDangid',
    width: '120px'
  }, {
    title: '客户类别',
    field: 'ctmLevelName',
    width: '100px',
    searchList: levelList2,
    formatter: getColumnValueRed
  }, {
    title: '会员等级',
    field: 'ctmMbetypeName',
    // searchList: this.importList2,
    width: '120px'
  }, {
    title: 'RFM类型',
    field: 'ctmMz',
    searchable: true
  }, {
    title: '绿色通道',
    field: 'isGreen',
    width: '80px',
    formatter: getColumnValueEditTable,
    searchList: yesOrNoList
  }, {
    title: '成交状态',
    field: 'ctfState',
    searchList: stateList,
    width: '80px',
    formatter: getColumnValueEditTable,
    filter: stateFilter
  }, {
    title: '脱毛标签',
    field: 'tmLabel',
    searchable: true,
    width: '120px',
    searchList: [{
      title: '纯脱毛',
      field: 1
    }, {
      title: '脱毛首转',
      field: 2
    }, {
      title: '空',
      field: 0
    }],
    formatter: function formatter(val, list, row) {
      return "".concat(row.tmLabel === 1 ? '纯脱毛' : '' || '');
    }
  }, {
    title: '体验/转化',
    field: 'tyOrZh',
    type: 'select',
    canEdit: tyCanEdit(),
    width: '80px',
    searchList: tyOrZhList,
    filter: tyOrZhFilter,
    formatter: getColumnValueEditTable
  }, {
    title: '转化',
    field: 'zhStr',
    width: '120px',
    searchable: true
  }, // {
  //   title: '潜在需求1',
  //   field: 'ctfQtype1',
  //   searchList: intensList,
  //   width: '100px',
  //   formatter: getColumnValueEditTable,
  // },
  // {
  //   title: '面诊方案',
  //   field: 'ctfMzFa',
  //   width: '100px',
  //   searchList: [],
  //   formatter: getColumnValueEditTable,
  // },
  // {
  //   title: '执行医生',
  //   field: 'ctfZzName',
  //   width: '80px',
  // },
  // {
  //   title: '未成交原因',
  //   field: 'ctfFalinfo',
  //   width: '100px',
  // },
  {
    title: '信息来源1',
    field: 'eptPName',
    width: '100px'
  }, {
    title: '信息来源2',
    field: 'eptName',
    width: '100px',
    formatter: function formatter(value, list, row) {
      if (row.eptNameStatus == 'STP') {
        return '<span style="color:red">' + row.eptName + '</span>';
      }

      return row.eptName;
    }
  }, {
    title: '介绍人',
    field: 'ctfJsPeople',
    width: '80px'
  }, {
    title: '介绍人卡号',
    field: 'ctfJsCtmcode',
    width: '110px'
  }, {
    title: '所属护理师',
    field: 'ctfNurseName',
    width: '100px'
  }, {
    title: '所属美容师',
    field: 'ctfBeauticianName',
    width: '100px'
  }, {
    title: '所属医生',
    field: 'ctmDocName'
  }, {
    title: '移动端',
    field: 'checkStatus',
    width: '80px',
    filter: checkStateFilter,
    formatter: getColumnValueEditTable,
    searchList: checkList
  }, {
    title: '指定服务',
    field: 'ctfDkServiceName',
    width: '80px'
  }, // {
  //   title: '接诊护理师',
  //   field: 'ctfJdNurseName',
  //   width: '120px',
  //   searchList: getEmployeeTypeList(),
  //   formatter: getColumnValueEditTable,
  // },
  {
    title: '接诊美容师',
    field: 'ctfJdBeauticianName',
    width: '120px',
    searchList: getEmployeeTypeList('jzhls'),
    formatter: getColumnValueEditTable
  }, // {
  //   title: '接诊医生',
  //   field: 'ctfJdDocName',
  //   width: '80px',
  //   searchList: getEmployeeTypeList('zzys'),
  //   formatter: getColumnValueEditTable,
  // },
  {
    title: '开发人员',
    field: 'kfPeopleName',
    width: '80px',
    searchList: getEmployeeTypeList('kf'),
    formatter: getColumnValueEditTable
  }, {
    title: '开发人员',
    field: 'ctfJsCtmcode',
    notColumn: true,
    width: '80px',
    searchList: getEmployeeTypeList('kf'),
    formatter: getColumnValueEditTable
  }, {
    title: '预约时间',
    field: 'fucTime',
    width: '95px'
  }, {
    title: '接诊时间',
    field: 'ctfJdTime',
    width: '95px'
  }, {
    title: '治疗开始时间',
    field: 'ctfZlStartTime',
    width: '95px'
  }, {
    title: '邀约人',
    field: 'ctfInvitingName',
    width: '80px'
  }, {
    title: '前次接诊顾问',
    field: 'ctfOldempName',
    width: '130px'
  }, {
    title: '接诊分院',
    field: 'jdEarId',
    width: '80px',
    searchList: this.eareList,
    formatter: getColumnValueEditTable
  }, {
    title: '所属分院',
    field: 'belongEarId',
    width: '80px',
    searchList: this.eareList,
    formatter: getColumnValueEditTable
  }, {
    title: '本次上门意向',
    field: 'ctfJzinfo',
    width: '100px'
  }, {
    title: '操作员',
    field: 'ctfOpterName',
    width: '80px',
    searchList: getEmployeeTypeList()
  }];
};
export var tabList = [{
  label: '客户资料',
  name: '1'
}, {
  label: '当前咨询情况',
  name: '2'
}, {
  label: '面部评估',
  name: '12',
  render: 'MbpgTable',
  ref: 'mbpgTable'
}, {
  label: '过往咨询',
  name: '4',
  render: 'GwTable',
  ref: 'gwTable'
}, {
  label: '客户交易',
  name: '3',
  render: 'XfTable',
  ref: 'xf'
}, {
  label: '回访管理',
  name: '5',
  render: 'rvTable',
  ref: 'rvTable'
}, {
  label: '预约登记',
  name: '6',
  render: 'yyTable',
  ref: 'yyTable'
}, {
  label: '存款记录',
  name: '9',
  render: 'ckTable',
  ref: 'ckTable'
}, {
  label: '券额记录',
  name: '10',
  render: 'qeTable',
  ref: 'qeTable'
}, {
  label: '积分记录',
  name: '11',
  render: 'JfTable',
  ref: 'jf'
}];
export function SelectProjectColumns(notColumn) {
  var _this = this;

  return {
    columns1ShowType: [{
      title: '序号',
      field: 'orderNum',
      searchable: false,
      width: '50px'
    }, {
      title: '项目系列',
      field: 'zptPtype1',
      searchable: true,
      width: '80px',
      searchList: this.type1,
      formatter: this.getColumnValueEditTable,
      onChange: this.getTypeTow,
      notColumn: true
    }, {
      title: '产品类别',
      field: 'zptPtype2',
      searchable: true,
      width: '80px',
      searchList: this.type2,
      formatter: this.getColumnValueEditTable,
      notColumn: true
    }, {
      title: '项目系列',
      field: 'pdtName1',
      width: '90px'
    }, {
      title: '产品类别',
      field: 'pdtName2',
      width: '90px'
    }, {
      title: '项目名称',
      field: 'zptName',
      searchable: true,
      width: '250px'
    }, {
      title: '项目编号',
      field: 'zptCode',
      searchable: true,
      width: '70px'
    }, {
      title: '拼音码',
      field: 'zptOldid',
      searchable: true,
      width: '80px'
    }, {
      title: '项目次数',
      field: 'zptNum',
      width: '60px'
    }, {
      title: '项目次数',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptNum',
      field1: 'maxZptNum'
    }, {
      title: '价值标签',
      field: 'zptPdulabel2',
      width: '60px',
      searchable: true,
      searchList: this.$store.state.common.zptPdulabel2List
    }, {
      title: '日常特价',
      field: 'zptPrice',
      width: '120px'
    }, {
      title: '会员价',
      field: 'zptLocprice',
      width: '120px'
    }, {
      title: '会员价',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptLocprice',
      field1: 'maxZptLocprice'
    }, {
      title: '会员单次价格',
      field: 'zptUprice',
      width: '120px'
    }, {
      title: '会员单次价格',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptUprice',
      field1: 'maxZptUprice'
    }, {
      title: '日常特价',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptPrice',
      field1: 'maxZptPrice'
    }, {
      title: '项目期限',
      field: 'zptSpercAmt',
      width: '120px'
    }, {
      title: '项目期限',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptSpercAmt',
      field1: 'maxZptSpercAmt'
    }, {
      title: '适应症一级',
      field: 'zptPasymptom',
      searchable: true,
      width: '120px',
      searchList: this.symptomList,
      onChange: this.getsymList,
      disabled: function disabled() {
        return !!_this.addParam;
      }
    }, {
      title: '适应症二级',
      field: 'zptSymptom',
      searchable: true,
      width: '120px',
      searchList: this.symptomList2,
      disabled: function disabled() {
        return !!_this.addParam;
      }
    }, {
      title: '项目科室',
      field: 'zptDptCode',
      width: '120px',
      searchable: true,
      searchList: this.departmentListAll,
      formatter: this.getColumnValueEditTable
    }, {
      title: '主操作',
      field: 'sgZczName',
      width: '100px'
    }, {
      title: '配台',
      field: 'sgPtName',
      width: '100px'
    }, {
      title: '护理',
      field: 'sgHlName',
      width: '100px'
    }, {
      title: '脱毛',
      field: 'sgTmName',
      width: '100px'
    }, {
      title: '纹绣',
      field: 'sgWxName',
      width: '100px'
    }, {
      title: '手工类别',
      field: 'zptStandard1',
      notColumn: true,
      searchable: true,
      width: '100px',
      operate: 'cascader',
      options: this.standardOptions,
      change: this.getCheckedNodesT,
      expandChange: this.expandChangeT
    }, {
      title: '计入成功率',
      field: 'zptIfsucrate',
      searchable: true,
      width: '100px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '是否需划扣',
      field: 'zptIfpaynext',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '可否改次数',
      field: 'zptCanChgTime',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '可否改价',
      field: 'zptCanChgPrice',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '是否无限赠送',
      field: 'canWxzs',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '使用权限',
      field: 'zptUseAuth',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '赠送积分',
      field: 'zptInttype',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '状态',
      field: 'zptStatus',
      // searchable: true,
      width: '80px',
      filter: this.$options.filters.statusFilter,
      searchList: [// { field: 'STP', title: '停用' },
      {
        field: 'USE',
        title: '使用'
      }]
    }, {
      title: '备注',
      field: 'zptRemark',
      width: '150px',
      notUpdateTable: true
    }, {
      title: '促销价',
      field: 'disPrice',
      width: '80px',
      type: 'input',
      canEdit: function canEdit() {
        return !!_this.activeRow;
      },
      fixed: 'right',
      notColumn: !!this.activeRow && this.activeRow.activityType !== 1
    }, {
      title: '活动内容',
      field: 'ruleContext',
      width: '80px',
      fixed: 'right',
      notColumn: !!this.activeRow
    }],
    columns1: [{
      title: '科室编码',
      field: 'zptDptCode',
      notColumn: true,
      width: '120px',
      notUpdateTable: true
    }, {
      title: '项目编号',
      field: 'zptCode',
      width: '80px',
      searchable: true
    }, {
      title: '拼音码查询',
      field: 'zptOldid',
      searchable: true,
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '项目名称',
      field: 'zptName',
      searchable: true,
      width: '120px'
    }, // {title:'使用次数',field:'zptNum',width:'80px'},
    {
      title: '项目次数',
      field: 'zptNum',
      width: '80px'
    }, {
      title: '价值标签',
      field: 'zptPdulabel2',
      width: '80px',
      notColumn: !!this.activeRow
    }, // {title:'推广标签',field:'zptPdulabel',searchable:true,width:'120px', searchList: labelOptions},
    {
      title: '会员价',
      field: 'zptLocprice',
      width: '120px',
      notColumn: !!this.activeRow
    }, {
      title: '会员单次价格',
      field: 'zptUprice',
      width: '120px',
      notColumn: !!this.activeRow
    }, {
      title: '日常特价',
      field: 'zptPrice',
      width: '80px'
    }, {
      title: '项目期限',
      field: 'zptSpercAmt',
      width: '80px'
    }, {
      title: '主操作',
      field: 'sgZczName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '配台',
      field: 'sgPtName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '护理',
      field: 'sgHlName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '脱毛',
      field: 'sgTmName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '纹绣',
      field: 'sgWxName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '手工类别',
      field: 'zptStandard1',
      notColumn: true,
      searchable: true,
      width: '100px',
      operate: 'cascader',
      options: this.standardOptions,
      change: this.getCheckedNodesT,
      expandChange: this.expandChangeT
    }, {
      title: '项目系列',
      field: 'zptPtype1',
      searchable: true,
      width: '80px',
      searchList: this.type1,
      formatter: this.getColumnValueEditTable,
      notColumn: true,
      onChange: this.getTypeTow
    }, {
      title: '产品类别',
      field: 'zptPtype2',
      searchable: true,
      width: '80px',
      searchList: this.type2,
      formatter: this.getColumnValueEditTable,
      notColumn: true
    }, {
      title: '项目系列',
      field: 'pdtName1',
      width: '90px',
      notColumn: notColumn
    }, {
      title: '产品类别',
      field: 'pdtName2',
      width: '90px',
      notColumn: notColumn
    }, {
      title: '适应症一级',
      field: 'zptPasymptom',
      searchable: true,
      width: '120px',
      searchList: this.symptomList,
      disabled: function disabled() {
        return !!_this.addParam;
      },
      onChange: this.getsymList,
      notColumn: notColumn
    }, {
      title: '适应症二级',
      field: 'zptSymptom',
      searchable: true,
      width: '120px',
      searchList: this.symptomList2,
      disabled: function disabled() {
        return !!_this.addParam;
      },
      notColumn: notColumn
    }, {
      title: '项目科室',
      field: 'departmentName',
      width: '120px',
      notColumn: notColumn
    }, {
      title: '状态',
      field: 'zptStatus',
      // searchable: true,
      width: '80px',
      filter: this.$options.filters.statusFilter,
      searchList: this.statusOptions,
      notColumn: !!this.activeRow
    }, {
      title: '备注',
      field: 'zptRemark',
      width: '150px',
      notColumn: !!this.activeRow
    }, {
      title: '促销价',
      field: 'disPrice',
      width: '80px',
      type: 'input',
      canEdit: function canEdit() {
        return !!_this.activeRow;
      },
      fixed: 'right',
      notColumn: !!this.activeRow && this.activeRow.activityType !== 1
    }, {
      title: '活动内容',
      field: 'ruleContext',
      width: '80px',
      fixed: 'right',
      notColumn: !!this.activeRow
    }],
    columns2ShowType: [{
      title: '序号',
      field: 'orderNum',
      searchable: false,
      width: '80px',
      notUpdateTable: true
    }, {
      title: '项目系列',
      field: 'pdtName1',
      width: '120px'
    }, {
      title: '项目系列',
      field: 'zptPtype1',
      notColumn: true,
      searchable: true,
      width: '120px',
      searchList: this.type1,
      onChange: this.getTypeTow
    }, {
      title: '产品类别',
      field: 'pdtName2',
      width: '120px'
    }, {
      title: '产品类别',
      field: 'zptPtype2',
      notColumn: true,
      searchable: true,
      width: '120px',
      searchList: this.type2
    }, {
      title: '项目名称',
      field: 'zptName',
      searchable: true,
      width: '120px'
    }, {
      title: '项目编号',
      field: 'zptCode',
      searchable: true,
      width: '150px'
    }, {
      title: '拼音码',
      field: 'zptOldid',
      searchable: true,
      width: '120px'
    }, {
      title: '项目次数',
      field: 'zptNum',
      searchable: true,
      width: '80px'
    }, {
      title: '价值标签',
      field: 'zptPdulabel2',
      width: '80px',
      searchable: true,
      searchList: this.$store.state.common.zptPdulabel2List
    }, // { title: '促销价', field: 'zptDisPrice', searchable: true, width: '80px' },
    // {
    //   title: '促销开始时间',
    //   field: 'zptDisStartDate',
    //
    //   searchable: true,
    //   width: '160px',
    // },
    // {
    //   title: '促销截止时间',
    //   field: 'zptDisDate',
    //
    //   searchable: true,
    //   width: '160px',
    // },
    {
      title: '日常特价',
      field: 'zptPrice',
      width: '120px'
    }, {
      title: '会员价',
      field: 'zptLocprice',
      width: '120px'
    }, {
      title: '会员价',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptLocprice',
      field1: 'maxZptLocprice'
    }, {
      title: '会员单次价格',
      field: 'zptUprice',
      width: '120px'
    }, {
      title: '会员单次价格',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptUprice',
      field1: 'maxZptUprice'
    }, {
      title: '日常特价',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptPrice',
      field1: 'maxZptPrice'
    }, // {title:'日常特价',field:'zptPrice',width:'120px'},
    {
      title: '项目期限',
      field: 'zptSpercAmt',
      searchable: true,
      width: '120px'
    }, {
      title: '适应症一级',
      field: 'zptPasymptom',
      searchable: true,
      width: '120px',
      searchList: this.symptomList,
      disabled: function disabled() {
        return !!_this.addParam;
      },
      onChange: this.getsymList
    }, {
      title: '适应症二级',
      field: 'zptSymptom',
      searchable: true,
      width: '120px',
      searchList: this.symptomList2,
      disabled: function disabled() {
        return !!_this.addParam;
      }
    }, {
      title: '项目科室',
      field: 'departmentName',
      width: '120px',
      searchable: true,
      searchList: this.departmentList
    }, {
      title: '主操作',
      field: 'sgZczName',
      width: '100px'
    }, {
      title: '配台',
      field: 'sgPtName',
      width: '100px'
    }, {
      title: '护理',
      field: 'sgHlName',
      width: '100px'
    }, {
      title: '脱毛',
      field: 'sgTmName',
      width: '100px'
    }, {
      title: '纹绣',
      field: 'sgWxName',
      width: '100px'
    }, {
      title: '计入成功率',
      field: 'zptIfsucrate',
      searchable: true,
      width: '100px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '是否需划扣',
      field: 'zptIfpaynext',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '可否改次数',
      field: 'zptCanChgTime',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '可否改价',
      field: 'zptCanChgPrice',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '是否无限赠送',
      field: 'canWxzs',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '赠送积分',
      field: 'zptInttype',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNoList
    }, {
      title: '状态',
      field: 'zptStatus',
      // searchable: true,
      width: '80px',
      filter: this.$options.filters.statusFilter,
      searchList: this.statusOptions
    }, {
      title: '备注',
      field: 'zptRemark',
      width: '150px'
    }, {
      title: '促销价',
      field: 'disPrice',
      width: '80px',
      type: 'input',
      canEdit: function canEdit() {
        return !!_this.activeRow;
      },
      fixed: 'right',
      notColumn: !!this.activeRow && this.activeRow.activityType !== 1
    }, {
      title: '活动内容',
      field: 'ruleContext',
      width: '80px',
      fixed: 'right',
      notColumn: !!this.activeRow
    }],
    columns2: [{
      title: '项目编号',
      field: 'zptCode',
      width: '80px',
      notUpdateTable: true
    }, {
      title: '拼音码',
      field: 'zptOldid',
      searchable: true,
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '项目名称',
      field: 'zptName',
      searchable: true,
      width: '120px'
    }, {
      title: '项目次数',
      field: 'zptNum',
      width: '80px'
    }, {
      title: '价值标签',
      field: 'zptPdulabel2',
      width: '80px',
      notColumn: !!this.activeRow
    }, {
      title: '推广标签',
      field: 'zptPdulabel',
      searchable: true,
      width: '100px',
      searchList: labelOptions,
      notColumn: notColumn
    }, // { title: '促销价', field: 'zptDisPrice', width: '80px', notColumn: !!this.activeRow },
    // {
    //   title: '促销开始时间',
    //   field: 'zptDisStartDate',
    //   width: '160px',
    //   notColumn: !!this.activeRow,
    // },
    // { title: '促销截止时间', field: 'zptDisDate', width: '160px', notColumn: !!this.activeRow },
    {
      title: '日常特价',
      field: 'zptPrice',
      width: '80px'
    }, {
      title: '日常特价',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptPrice',
      field1: 'maxZptPrice'
    }, {
      title: '项目期限',
      field: 'zptSpercAmt',
      width: '80px'
    }, {
      title: '主操作',
      field: 'sgZczName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '配台',
      field: 'sgPtName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '护理',
      field: 'sgHlName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '脱毛',
      field: 'sgTmName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '纹绣',
      field: 'sgWxName',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '项目系列',
      field: 'pdtName1',
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '项目系列',
      field: 'zptPtype1',
      notColumn: true,
      searchable: true,
      width: '100px',
      searchList: this.type1,
      onChange: this.getTypeTow
    }, {
      title: '产品类别',
      field: 'pdtName2',
      width: '120px',
      notColumn: !!this.activeRow
    }, {
      title: '产品类别',
      field: 'zptPtype2',
      notColumn: true,
      searchable: true,
      width: '120px',
      searchList: this.type2
    }, {
      title: '适应症一级',
      field: 'zptPasymptom',
      searchable: true,
      width: '120px',
      searchList: this.symptomList,
      disabled: function disabled() {
        return !!_this.addParam;
      },
      onChange: this.getsymList,
      notColumn: notColumn
    }, {
      title: '适应症二级',
      field: 'zptSymptom',
      searchable: true,
      width: '120px',
      searchList: this.symptomList2,
      disabled: function disabled() {
        return !!_this.addParam;
      },
      notColumn: notColumn
    }, {
      title: '项目科室',
      field: 'departmentName',
      width: '120px',
      notColumn: notColumn
    }, {
      title: '状态',
      field: 'zptStatus',
      // searchable: true,
      width: '80px',
      filter: this.$options.filters.statusFilter,
      searchList: this.statusOptions,
      notColumn: !!this.activeRow
    }, {
      title: '备注',
      field: 'zptRemark',
      width: '150px',
      notColumn: !!this.activeRow
    }, {
      title: '促销价',
      field: 'disPrice',
      width: '80px',
      type: 'input',
      canEdit: function canEdit() {
        return !!_this.activeRow;
      },
      fixed: 'right',
      notColumn: !!this.activeRow && this.activeRow.activityType !== 1
    }, {
      title: '活动内容',
      field: 'ruleContext',
      width: '80px',
      fixed: 'right',
      notColumn: !!this.activeRow
    }],
    columns4: [// { title: '一级套餐', field: 'pthType1Name', searchable: true, width: '150px' },
    // { title: '二级套餐', field: 'pthType2pthName', searchable: true, width: '150px' },
    {
      title: '套餐编码',
      field: 'pthCode',
      searchable: true,
      width: '80px',
      notUpdateTable: true
    }, {
      title: '套餐名称',
      field: 'pthName',
      searchable: true,
      width: '150px'
    }, {
      title: '拼音码',
      field: 'pthOldid',
      searchable: false,
      width: '100px',
      notColumn: !!this.activeRow
    }, {
      title: '会员价',
      field: 'pthAmt',
      searchable: false,
      width: '80px',
      notColumn: !!this.activeRow
    }, {
      title: '会员价',
      notColumn: true,
      searchable: true,
      operate: 'numRange',
      field: 'minPthAmt',
      field1: 'maxPthAmt'
    }, {
      title: '日常特价',
      field: 'pthTolamt',
      searchable: false,
      width: '80px'
    }, {
      title: '日常特价',
      notColumn: true,
      searchable: true,
      operate: 'numRange',
      field: 'minPthTolamt',
      field1: 'maxPthTolamt'
    }, {
      title: '促销截止时间',
      field: 'pthFndate',
      notColumn: true,
      searchable: false,
      width: '80px'
    }, {
      title: '总次数',
      field: 'pthTolnum',
      searchable: false,
      width: '100px'
    }, {
      title: '总次数',
      notColumn: true,
      searchable: true,
      operate: 'numRange',
      field: 'minPthTolnum',
      field1: 'maxPthTolnum'
    }, {
      title: '一级套餐',
      field: 'pthType1Name',
      width: '80px'
    }, {
      title: '二级套餐',
      field: 'pthType2Name',
      width: '80px'
    }, // {
    //   title: '一级套餐',
    //   field: 'pthType1',
    //   notColumn: true,
    //   searchable: true,
    //   width: '90px',
    //   searchList: this.topType,
    //   onChange: this.getTcType,
    // },
    // {
    //   title: '二级套餐',
    //   field: 'pthType2',
    //   notColumn: true,
    //   searchable: true,
    //   width: '90px',
    //   searchList: this.tcType1,
    // },
    // {
    //   title: '适应症一级',
    //   field: 'pthPasymptom',
    //   searchable: false,
    //
    //   width: '80px',
    //   notColumn,
    // },
    // { title: '适应症二级', field: 'pthSymptom', searchable: false, width: '80px', notColumn },
    // {
    //   title: '套餐类型',
    //   field: 'pthCtype',
    //   searchable: false,
    //
    //   width: '120px',
    //   filter: this.$options.filters.typeFilter,
    //   notColumn: !!this.activeRow,
    // },
    // {title:'可选项目数',field:'pthLeastnum',searchable:true,width:'100px'},
    // {title:'有效期',field:'jsPeople',searchable:true,width:'80px'},
    {
      title: '状态',
      field: 'pthStatus',
      // searchable: true,
      width: '80px',
      filter: this.$options.filters.statusFilter,
      searchList: [// { field: 'STP', title: '停用' },
      {
        field: 'USE',
        title: '使用'
      }],
      notColumn: !!this.activeRow
    }, {
      title: '说明',
      field: 'pthRemark',
      searchable: false,
      width: '150px',
      notColumn: !!this.activeRow
    }, {
      title: '促销价',
      field: 'disPrice',
      width: '80px',
      type: 'input',
      canEdit: function canEdit() {
        return !!_this.activeRow;
      },
      fixed: 'right',
      notColumn: !!this.activeRow && this.activeRow.activityType !== 1
    }, {
      title: '活动内容',
      field: 'ruleContext',
      width: '80px',
      fixed: 'right',
      notColumn: !!this.activeRow
    }],
    columnsProduct: [{
      title: '物品编号',
      field: 'zptCode',
      searchable: true,
      width: '100px',
      notUpdateTable: true
    }, {
      title: '物品系列',
      field: 'pdtName1',
      width: '80px',
      notColumn: notColumn
    }, {
      title: '物品类别',
      field: 'pdtName2',
      width: '80px',
      notColumn: notColumn
    }, {
      title: '价值标签',
      field: 'zptPdulabel2',
      notColumn: true,
      searchable: true,
      width: '150px',
      searchList: this.$store.state.common.zptPdulabel2List,
      notUpdateTable: true
    }, {
      title: '零售价',
      field: 'minZptPrice',
      field1: 'maxZptPrice',
      operate: 'numRange',
      notColumn: true,
      searchable: true,
      width: '150px'
    }, {
      title: '物品名称',
      field: 'zptName',
      searchable: true,
      width: '80px'
    }, {
      title: '拼音码',
      field: 'zptOldid',
      searchable: true,
      width: '100px',
      notColumn: !!this.activeRow
    }, // {title:'五笔码查询',field:'fucMap',searchable:true,width:'100px'},
    {
      title: '价值标签',
      field: 'zptPdulabel2',
      width: '80px',
      notColumn: notColumn
    }, {
      title: '零售价',
      field: 'zptPrice',
      width: '80px'
    }, {
      title: '规格',
      field: 'zptOrder',
      width: '80px'
    }, // {title:'销售价',field:'zptPrice', width:'80px'},
    {
      title: '单位',
      field: 'unitName',
      width: '80px'
    }, // {title:'最小单位',field:'minUnitName', width:'80px'},
    // {title:'单位进制',field:'zptAddunit', width:'80px'},
    {
      title: '库存上限',
      field: 'zptInlimt',
      width: '80px',
      notColumn: !!this.activeRow
    }, {
      title: '库存下限',
      field: 'zptOutlimt',
      width: '80px',
      notColumn: !!this.activeRow
    }, // {title:'工种提成',field:'zptEmpperc', width:'80px',filter: this.$options.filters.percFilter},
    // {title:'是否同步',field:'ctfName', width:'80px'},
    {
      title: '项目科室',
      field: 'zptDptCode',
      width: '80px',
      searchable: true,
      searchList: this.departmentListAll,
      formatter: this.getColumnValueEditTable,
      notColumn: !!this.activeRow
    }, // {title:'项目科室',field:'departmentName', width:'80px'},
    // {
    //   title: '状态',
    //   field: 'zptStatus',
    //   width: '80px',
    //   searchable: true,
    //   searchList: this.statusUseStpList,
    //   filter: this.$options.filters.statusFilter,
    // },
    // {title:'咨询成功率',field:'ctfName', width:'100px'},
    // {title:'修改次数',field:'zptCanChgTime', width:'80px',searchable: true,searchList: this.yesOrNoList,filter: this.$options.filters.setFilter},
    {
      title: '备注',
      field: 'zptRemark',
      width: '80px',
      notColumn: !!this.activeRow
    }, {
      title: '促销价',
      field: 'disPrice',
      width: '80px',
      type: 'input',
      canEdit: function canEdit() {
        return !!_this.activeRow;
      },
      fixed: 'right',
      notColumn: !!this.activeRow && this.activeRow.activityType !== 1
    }, {
      title: '活动内容',
      field: 'ruleContext',
      width: '80px',
      fixed: 'right',
      notColumn: !!this.activeRow
    }],
    columnsLgProduct: [{
      title: '仓库',
      field: 'stoStock',
      notColumn: true,
      searchable: true,
      width: '80px',
      type: 'text',
      searchList: this.$store.state.common.storageList,
      formatter: this.getColumnValue,
      notUpdateTable: true
    }, {
      title: '产品系列',
      field: 'zptPtypeName1',
      searchable: false,
      width: '80px'
    }, {
      title: '产品系列',
      field: 'zptPtype1',
      notColumn: true,
      searchable: true,
      width: '80px',
      searchList: this.type1,
      onChange: this.getTypeTow
    }, {
      title: '产品类别',
      field: 'zptPtypeName2',
      searchable: false,
      width: '80px'
    }, {
      title: '产品类别',
      field: 'zptPtype2',
      notColumn: true,
      searchable: true,
      width: '80px',
      searchList: this.type2
    }, {
      title: '产品编号',
      field: 'stoZptcode',
      searchable: true,
      width: '60px'
    }, {
      title: '产品名称',
      field: 'zptName',
      searchable: true,
      width: '200px',
      formSearch: true
    }, {
      title: '拼音码查询',
      field: 'zptOldid',
      searchable: true,
      width: '100px',
      formSearch: true
    }, {
      title: '规格',
      field: 'zptOrder',
      searchable: false,
      width: '60px'
    }, {
      title: '单位',
      field: 'zptUnitName',
      searchable: false,
      width: '60px'
    }, {
      title: '价格',
      field: 'zptPrice',
      searchable: false,
      width: '60px'
    }, {
      title: '可用库存',
      field: 'stoStnum',
      searchable: false,
      width: '80px'
    }, {
      title: '实际库存',
      field: 'stoNum',
      searchable: false,
      width: '80px'
    }, {
      title: '促销价',
      field: 'disPrice',
      width: '80px',
      type: 'input',
      canEdit: function canEdit() {
        return !!_this.activeRow;
      },
      fixed: 'right',
      notColumn: !!this.activeRow && this.activeRow.activityType !== 1
    }, {
      title: '活动内容',
      field: 'ruleContext',
      width: '80px',
      fixed: 'right',
      notColumn: !!this.activeRow
    }]
  };
}