var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "baseForm",
          attrs: {
            inline: _vm.inline,
            "show-message": _vm.showMessage,
            model: _vm.formObj,
            rules: _vm.rules,
            size: _vm.size,
            "label-position": _vm.labelPosition,
            "label-width": _vm.labelWidth,
            disabled: _vm.disabled || _vm.empCode === "Y0032",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formKeyUpFun($event)
            },
          },
        },
        [
          _vm.showHeader
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c("el-form-item", { staticStyle: { width: "100%" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#1890ff",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("客户基本信息")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#F56C6C",
                              "font-weight": "bold",
                              "padding-left": "10px",
                            },
                          },
                          [
                            _vm._v(
                              "【初诊日期：" +
                                _vm._s(
                                  _vm.formObj.firstDate &&
                                    _vm.formObj.firstDate.substring(0, 11)
                                ) +
                                "】"
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属分院:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formObj.belongEarName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formObj, "belongEarName", $$v)
                              },
                              expression: "formObj.belongEarName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showTopFormItem || _vm.showValueFormItem
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: "RFM类别:" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "160px" },
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.formObj.ctmMz,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formObj, "ctmMz", $$v)
                                    },
                                    expression: "formObj.ctmMz",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "会员等级:" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  disabled: "",
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "",
                                },
                                model: {
                                  value: _vm.formObj.ctmMbetype,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formObj, "ctmMbetype", $$v)
                                  },
                                  expression: "formObj.ctmMbetype",
                                },
                              },
                              _vm._l(_vm.vipLevelList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.title,
                                    value: "" + item.field,
                                    disabled: item.disabled,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      _vm._v(" "),
                      _vm.showTmLabel
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "脱毛标签:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.formObj.tmLabel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formObj, "tmLabel", $$v)
                                    },
                                    expression: "formObj.tmLabel",
                                  },
                                },
                                _vm._l(
                                  [
                                    {
                                      title: "纯脱毛",
                                      field: 1,
                                    },
                                    {
                                      title: "脱毛首转",
                                      field: 2,
                                    },
                                    {
                                      title: "无",
                                      field: -1,
                                    },
                                  ],
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.title,
                                        value: item.field,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showTopFormItem
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户类别:" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100px" },
                                    attrs: { filterable: "", clearable: "" },
                                    model: {
                                      value: _vm.formObj.ctmLevel,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formObj, "ctmLevel", $$v)
                                      },
                                      expression: "formObj.ctmLevel",
                                    },
                                  },
                                  _vm._l(
                                    _vm.levelList2,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        style: {
                                          color:
                                            item.title === "投诉顾客"
                                              ? "red"
                                              : "",
                                        },
                                        attrs: {
                                          label: item.title,
                                          value: item.field,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活跃度:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "80%" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formObj.cusStatusFqName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formObj, "cusStatusFqName", $$v)
                              },
                              expression: "formObj.cusStatusFqName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._t("form-header"),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-avatar", {
                        staticClass: "center-avatar",
                        attrs: {
                          fit: "contain",
                          shape: "square",
                          size: 80,
                          src: _vm.formObj.ctmAvatar
                            ? _vm.formObj.ctmAvatar
                            : require("@/assets/images/avatar.png"),
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handlePhoto($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.formDate, function (formItem, index) {
            return _c(
              "el-row",
              { key: index },
              [
                formItem.type === "formSlot"
                  ? _vm._t(formItem.slotName)
                  : formItem.type === "spanHead"
                  ? _c("el-form-item", { staticStyle: { width: "100%" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#1890ff",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v(_vm._s(formItem.span))]
                      ),
                    ])
                  : formItem.type === "ygPop"
                  ? _vm._l(_vm.ygPopList, function (pos, code) {
                      return _c(
                        "span",
                        {
                          key: code,
                          staticStyle: { float: "left" },
                          attrs: { span: 12 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: pos.title, prop: pos.prop } },
                            [
                              (
                                _vm.unitDisabled
                                  ? false
                                  : !_vm.disabledFuc
                                  ? true
                                  : _vm.adjustType
                                  ? _vm.consultDisabled
                                    ? !_vm.formObj[pos.disabledStr]
                                    : _vm.isFunction(pos.disabledStr)
                                    ? pos.disabledStr()
                                    : _vm.depAjustDisabled &&
                                      !_vm.formObj[pos.disabledStr]
                                  : false
                              )
                                ? _c(
                                    "div",
                                    { ref: pos.ref, refInFor: true },
                                    [
                                      _c("yg-popover", {
                                        attrs: {
                                          positionType: pos.positionType,
                                          title: pos.title,
                                          code: _vm.formObj[pos.code],
                                          disabled: pos.disabled,
                                        },
                                        on: {
                                          select: function ($event) {
                                            return _vm.handleSelectPop(
                                              $event,
                                              pos
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.formObj[pos.name],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formObj, pos.name, $$v)
                                          },
                                          expression: "formObj[pos.name]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("el-input", {
                                    staticStyle: { width: "80%" },
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.formObj[pos.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formObj, pos.name, $$v)
                                      },
                                      expression: "formObj[pos.name]",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    })
                  : formItem.type === "ygPop2"
                  ? _vm._l(_vm.ygPopList2, function (pos, code) {
                      return _c(
                        "span",
                        { key: code, staticStyle: { float: "left" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: pos.title, prop: pos.prop } },
                            [
                              (
                                _vm.unitDisabled
                                  ? false
                                  : !_vm.disabledFuc
                                  ? true
                                  : _vm.adjustType
                                  ? !_vm.formObj[pos.disabledStr]
                                  : false
                              )
                                ? _c(
                                    "div",
                                    { ref: pos.ref, refInFor: true },
                                    [
                                      _c("yg-popover", {
                                        attrs: {
                                          positionType: pos.positionType,
                                          title: pos.title,
                                          code: _vm.formObj[pos.code],
                                        },
                                        on: {
                                          select: function ($event) {
                                            return _vm.handleSelectPop(
                                              $event,
                                              pos
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.formObj[pos.name],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formObj, pos.name, $$v)
                                          },
                                          expression: "formObj[pos.name]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("el-input", {
                                    staticStyle: { width: "80%" },
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.formObj[pos.name],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formObj, pos.name, $$v)
                                      },
                                      expression: "formObj[pos.name]",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    })
                  : _vm._l(formItem, function (item, index2) {
                      return _c(
                        "el-form-item",
                        {
                          key: index2,
                          class:
                            item.type === "jsrCode" ? "content_width" : null,
                          attrs: {
                            label: item.label,
                            prop:
                              item.type !== "jsrCode"
                                ? item.prop || item.model
                                : _vm.formObj.eptId == 29 ||
                                  _vm.formObj.eptId == 30
                                ? item.model
                                : "2",
                            "label-width": item.labelWidth,
                          },
                        },
                        [
                          item.type === "text" || item.type === "number"
                            ? [
                                _c("el-input", {
                                  style: item.style || "width: 80%",
                                  attrs: {
                                    disabled: item.disabled,
                                    type: item.type,
                                  },
                                  model: {
                                    value: _vm.formObj[item.model],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formObj, item.model, $$v)
                                    },
                                    expression: "formObj[item.model]",
                                  },
                                }),
                                _vm._v(" "),
                                item.btnType
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: item.btnType,
                                          plain: item.btnTypePlain,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item.btnText) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          item.type === "inputPopover"
                            ? [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top",
                                      width: "300",
                                      trigger: "click",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      directives: [
                                        { name: "focus", rawName: "v-focus" },
                                      ],
                                      attrs: {
                                        disabled: item.disabled,
                                        type: "textarea",
                                      },
                                      model: {
                                        value: _vm.formObj[item.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formObj, item.model, $$v)
                                        },
                                        expression: "formObj[item.model]",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      style: item.style || "width: 80%",
                                      attrs: {
                                        slot: "reference",
                                        disabled: item.disabled,
                                        type: item.type,
                                      },
                                      slot: "reference",
                                      model: {
                                        value: _vm.formObj[item.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formObj, item.model, $$v)
                                        },
                                        expression: "formObj[item.model]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : item.type === "date"
                            ? [
                                _c("el-date-picker", {
                                  style: item.style || "width: 80%",
                                  attrs: {
                                    disabled: item.disabled,
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    clearable: false,
                                    "picker-options":
                                      item.label === "生日:"
                                        ? _vm.pickerOptions
                                        : null,
                                  },
                                  model: {
                                    value: _vm.formObj[item.model],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formObj, item.model, $$v)
                                    },
                                    expression: "formObj[item.model]",
                                  },
                                }),
                              ]
                            : item.type === "radio"
                            ? _vm._l(
                                item.radioList,
                                function (rodio, rodioIndex) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: rodioIndex,
                                      attrs: {
                                        disabled: item.disabled,
                                        label: rodio.radioLabel,
                                      },
                                      model: {
                                        value: _vm.formObj[item.model],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formObj, item.model, $$v)
                                        },
                                        expression: "formObj[item.model]",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(rodio.radioName) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                }
                              )
                            : item.type === "select"
                            ? [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "80%" },
                                    attrs: {
                                      filterable: "",
                                      disabled:
                                        item.disabled === false ? false : true,
                                    },
                                    on: {
                                      change: function ($event) {
                                        item.change && item.change()
                                      },
                                    },
                                    model: {
                                      value: _vm.formObj[item.model],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formObj, item.model, $$v)
                                      },
                                      expression: "formObj[item.model]",
                                    },
                                  },
                                  _vm._l(
                                    item.selectList,
                                    function (item, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: index,
                                          attrs: {
                                            label: item.title,
                                            value: item.field,
                                          },
                                        },
                                        [
                                          item.empStatus
                                            ? _c(
                                                "span",
                                                {
                                                  style: {
                                                    color:
                                                      item.empStatus !== "CFM"
                                                        ? "#ff4949"
                                                        : "#606266",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]
                            : item.type === "slot"
                            ? [_vm._t(item.slotName)]
                            : item.type === "textarea"
                            ? [
                                _c("el-input", {
                                  staticStyle: { width: "25vw" },
                                  attrs: { type: "textarea", rows: 7 },
                                  model: {
                                    value: _vm.formObj[item.model],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formObj, item.model, $$v)
                                    },
                                    expression: "formObj[item.model]",
                                  },
                                }),
                              ]
                            : item.type === "xxly"
                            ? [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "信息来源:",
                                      "label-width": "85px",
                                      prop: _vm.eptPidOreptId,
                                    },
                                  },
                                  [
                                    !_vm.xxlyDisabled
                                      ? _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100px" },
                                            attrs: {
                                              filterable: "",
                                              disabled: _vm.xxlyDisabled,
                                            },
                                            on: { change: _vm.selectSource },
                                            model: {
                                              value: _vm.formObj.eptPid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formObj,
                                                  "eptPid",
                                                  $$v
                                                )
                                              },
                                              expression: "formObj.eptPid",
                                            },
                                          },
                                          _vm._l(
                                            _vm.copyList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                style: {
                                                  color: item.disabled
                                                    ? "red"
                                                    : "",
                                                },
                                                attrs: {
                                                  label: item.title,
                                                  disabled: item.disabled,
                                                  value: item.field,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.caltypeList2.length > 0 &&
                                    !_vm.xxlyDisabled
                                      ? _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              width: "100px",
                                              "margin-left": "10px",
                                            },
                                            attrs: {
                                              filterable: "",
                                              disabled: _vm.xxlyDisabled,
                                            },
                                            model: {
                                              value: _vm.formObj.eptId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formObj,
                                                  "eptId",
                                                  $$v
                                                )
                                              },
                                              expression: "formObj.eptId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.caltypeList2,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                style: {
                                                  color: item.disabled
                                                    ? "red"
                                                    : "",
                                                },
                                                attrs: {
                                                  disabled: item.disabled,
                                                  label: item.title,
                                                  value: item.field,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.xxlyDisabled
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "12px",
                                              "padding-top": "5px",
                                              "font-size": "13px",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.formObj.eptPName)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm.formObj.eptName
                                              ? _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      color:
                                                        _vm.formObj
                                                          .eptNameStatus ==
                                                        "STP"
                                                          ? "red"
                                                          : "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "--" +
                                                        _vm._s(
                                                          _vm.formObj.eptName
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : item.type === "jsrCode"
                            ? [
                                _c("el-input", {
                                  staticStyle: { width: "35%" },
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.formObj[item.model],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formObj, item.model, $$v)
                                    },
                                    expression: "formObj[item.model]",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      padding: "5px 5px",
                                      "margin-right": "0",
                                    },
                                    attrs: {
                                      type: "primary",
                                      plain: "",
                                      disabled: item.disabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.customerDialogVisible = true
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      padding: "5px 5px",
                                      "margin-left": "0",
                                    },
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      disabled: item.disabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearJsSave()
                                      },
                                    },
                                  },
                                  [_vm._v("清空")]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          item.checkboxModel
                            ? _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-left": "0px" },
                                  attrs: { disabled: item.disabled },
                                  model: {
                                    value: _vm.formObj[item.checkboxModel],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formObj,
                                        item.checkboxModel,
                                        $$v
                                      )
                                    },
                                    expression: "formObj[item.checkboxModel]",
                                  },
                                },
                                [_vm._v(_vm._s(item.checkboxName))]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
              ],
              2
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showlocationMap
        ? [
            _c(
              "div",
              { staticClass: "map-select" },
              [
                _c("div", { staticClass: "item" }, [
                  _vm._v("\n        客户地址\n      "),
                ]),
                _vm._v(" "),
                _c("location-map", {
                  ref: "map",
                  attrs: {
                    isSubmit: false,
                    height: 300,
                    address: _vm.formObj.ctmAddr,
                    lng: _vm.formObj.ctmLongitude,
                    lat: _vm.formObj.ctmLatitude,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.wallDialog
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "客户相册",
                "close-on-click-modal": false,
                visible: _vm.wallDialog,
                width: "730px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.wallDialog = $event
                },
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-left": "20px",
                    "margin-bottom": "10px",
                  },
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addWall },
                },
                [_vm._v("新增相册")]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                _vm._l(_vm.wallList, function (item, index) {
                  return _c(
                    "el-col",
                    {
                      key: index,
                      staticStyle: {
                        "text-align": "center",
                        padding: "10px",
                        "margin-left": "40px",
                        height: "240px",
                      },
                      attrs: { span: 6, offset: index > 0 ? 3 : 0 },
                    },
                    [
                      _c(
                        "el-card",
                        { attrs: { "body-style": { padding: "0px" } } },
                        [
                          _c("img", {
                            staticStyle: { height: "150px", width: "150px" },
                            attrs: { src: item.bgImg },
                            on: {
                              click: function ($event) {
                                return _vm.goPicList(item.id, item.typeName)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "padding-top": "10px" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _vm.wallEditNum == -1 ||
                                  _vm.wallEditNum != index
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.typeName)),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.wallEditNum == -1 ||
                                  _vm.wallEditNum != index
                                    ? _c("i", {
                                        staticClass: "el-icon-edit",
                                        on: {
                                          click: function ($event) {
                                            _vm.wallEditNum = index
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.wallEditNum == index
                                    ? _c("el-input", {
                                        staticStyle: { width: "120px" },
                                        model: {
                                          value: item.typeName,
                                          callback: function ($$v) {
                                            _vm.$set(item, "typeName", $$v)
                                          },
                                          expression: "item.typeName",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _vm.wallEditNum == index
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "button",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.savePicWall(
                                                index,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("保存")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "button",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteWall(item)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "40px" } }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.picDialog
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.picLoading,
                  expression: "picLoading",
                },
              ],
              attrs: {
                title: _vm.wallTitle + "照片列表",
                "close-on-click-modal": false,
                visible: _vm.picDialog,
                width: "730px",
                "before-close": _vm.picListClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.picDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    { name: "dialogHeight", rawName: "v-dialogHeight" },
                  ],
                  staticStyle: { "padding-bottom": "40px" },
                },
                [
                  _c("img-upload", {
                    ref: "upload",
                    attrs: { showFileList: false, multiple: "" },
                    on: {
                      uploadImgSuccess: _vm.handleAvatarSuccess,
                      change: function ($event) {
                        _vm.picLoading = false
                      },
                      progress: function ($event) {
                        _vm.picLoading = true
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.picList && _vm.picList.length > 0
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        _vm._l(_vm.picList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "el-upload-list__item is-success",
                              staticStyle: {
                                "margin-left": "20px",
                                "margin-top": "20px",
                                float: "left",
                                height: "180px",
                              },
                              attrs: { tabindex: index },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                  "margin-left": "24px",
                                  "margin-top": "5px",
                                },
                                attrs: { src: item.ctmPhoto },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-actions",
                                  staticStyle: { height: "62%" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-preview",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-zoom-in",
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewPic(item.ctmPhoto)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deletePic(item)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _vm.picEditNum == -1 ||
                                  _vm.picEditNum != index
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.ctmRemark)),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.picEditNum == -1 ||
                                  _vm.picEditNum != index
                                    ? _c("i", {
                                        staticClass: "el-icon-edit",
                                        on: {
                                          click: function ($event) {
                                            _vm.picEditNum = index
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.picEditNum == index
                                    ? _c("el-input", {
                                        staticStyle: {
                                          width: "120px",
                                          height: "20px",
                                        },
                                        model: {
                                          value: item.ctmRemark,
                                          callback: function ($$v) {
                                            _vm.$set(item, "ctmRemark", $$v)
                                          },
                                          expression: "item.ctmRemark",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    "text-align": "center",
                                    "margin-top": "5px",
                                  },
                                },
                                [
                                  _vm.picEditNum == index
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "button",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.savePic(index, [item])
                                            },
                                          },
                                        },
                                        [_vm._v("保存")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "button",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setHead(item)
                                        },
                                      },
                                    },
                                    [_vm._v("设置为头像")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.picViewDialog,
            "before-close": _vm.picViewClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.picViewDialog = $event
            },
          },
        },
        [_c("img", { attrs: { width: "90%", src: _vm.bigImg } })]
      ),
      _vm._v(" "),
      _vm.customerDialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "客户列表",
                visible: _vm.customerDialogVisible,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "100px",
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.customerDialogVisible = $event
                },
              },
            },
            [
              _c("kh-table", {
                ref: "customerTable",
                attrs: {
                  "ear-id": _vm.earId,
                  item: _vm.formObj,
                  ctfJsCtmcode: _vm.formObj.ctfCtmcode || _vm.formObj.ctmCode,
                  height: _vm.innerHeight - 250,
                },
                on: { select: _vm.handleSelect },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }